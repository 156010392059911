import Vue from 'vue';
import 'lib-flexible/flexible';
import {
  Lazyload, NavBar, Icon, Overlay,
  Tab, Tabs,
  Swipe, SwipeItem
} from 'vant';
import Page from './page.vue';

Vue.use(Lazyload);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.config.productionTip = false;
Vue.prototype.$eventbus = new Vue()

new Vue({
  render: (h) => h(Page),
}).$mount('#app');
