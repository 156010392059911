<template>
  <div id="app">
    <cHeader></cHeader>
    <div class="page page-home">
      <div class="banner"></div>
      <div class="top-bg">
        <loginIframe :isPopup="false" channelId="14771732"></loginIframe>
      </div>
      <div class="part aboutus first">
        <div class="title">关于我们</div>
        <div class="part-content">
          <div class="desc">
            VIPKID是一家为全球家庭提供一站式在线语言学习的数字化平台，<span class="strong">服务全球60多个国家的近百万家庭用户。</span>平台围绕教育、科技、服务三大内核，致力于为全球学员提供高质量的英语教学服务。
          </div>
          <img src="./img/stats1.png" class="stats" />
        </div>
      </div>
      <div class="part reason">
        <div class="title">选择VIPKID的理由</div>
        <div class="part-content">
          <Swiper :list="reasonList"></Swiper>
        </div>
      </div>
      <EntryPart type="1" channelId="14771733" index="1"/>
      <TeacherPart />
      <div class="part product">
        <div class="title">多元化产品服务</div>
        <div class="part-content">
          <div class="desc">满足家庭语言的学习需求，提升语言综合能力</div>
          <div class="tab-panel">
            <van-tabs :ellipsis="false">
              <van-tab v-for="(product, index) in productList" :key="product.title" :title="product.title">
                <div class="product-item">
                  <div class="desc-panel">
                    <img src="../img/icon-dot.png" />
                    <div class="text">{{ product.desc }}</div>
                  </div>
                  <template v-if="product.listType === 'block'">
                    <div class="block-list">
                      <div v-for="listItem in product.list" :key="listItem.title" class="block-item">
                        <div class="title-panel">
                          <div class="title">{{ listItem.title }}</div>
                          <div class="sub-title">{{ listItem.subTitle }}</div>
                        </div>
                        <div class="block-panel" :class="listItem.color">
                          <template v-if="product.itemType === 'column'">
                            <div v-for="column in listItem.itemList" class="column-item">
                              <div class="text1">{{ column.text1 }}</div>
                              <div class="text2">{{ column.text2 }}</div>
                            </div>
                          </template>
                          <template v-if="product.itemType === 'list'">
                            <div class="ul-list">
                              <div v-for="text in listItem.itemList" :key="text" class="ul-item">
                                <div class="dot"></div>
                                <div class="text">{{ text }}</div>
                              </div>
                            </div>
                          </template>
                          <template v-if="product.itemType === 'text'">
                            <div class="text">{{ listItem.itemText }}</div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="product.listType === 'card'">
                    <div class="card-list">
                      <div v-for="item in product.list" :key="item.title" class="card-item">
                        <div class="title">{{ item.title }}</div>
                        <div class="card-body">
                          <img :src="item.imgUrl" />
                          <div class="text">{{ item.itemText }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="btn" @click="jump(product)">了解更多</div>
                </div>
              </van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
      <div class="part class">
        <div class="title">生动有趣的互动课堂</div>
        <div class="part-content">
          <div class="desc">生动有趣的互动课堂  激发孩子的学习兴趣</div>
          <div class="video-panel">
            <VideoPlayer src="https://dl.vipkidstatic.com/prt/static/common/1714468914870/classes.mp4" :poster="require('./img/video-poster.png')" name="class"/>
          </div>
          <div class="desc-list">
            <div v-for="item in classDescList" :key="item.title" class="desc-item">
              <img src="../img/icon-dot.png" />
              <div class="text-panel">
                <div class="title">{{ item.title }}</div>
                <div class="desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part tech">
        <div class="title">科技赋能 守护家庭教育</div>
        <div class="part-content">
          <div class="card-list">
            <div v-for="item in techDescList" :key="item.title" class="card-item">
              <div class="left">
                <div class="title">{{ item.title }}</div>
                <div class="desc-list">
                  <div v-for="text in item.descList" :key="text" class="desc-item">{{ text }}</div>
                </div>
              </div>
              <div class="right">
                <img :src="item.imgUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part step">
        <div class="title">
          <div>如何免费体验</div>
          <div>英语试听课</div>
        </div>
        <div class="part-content">
          <div class="desc">3步轻松领取免费英语试听课</div>
          <div class="desc-panel">
            <img src="./img/step1.png" />
          </div>
        </div>
      </div>
      <EntryPart type="2" channelId="14771734" index="2"/>
      <div class="part partner">
        <div class="title">学术合作</div>
        <div class="part-content">
          <div class="info">
            <img src="./img/partner1.png" />
            <div class="line"></div>
            <img src="./img/partner2.png" />
          </div>
        </div>
      </div>
      <div class="part award">
        <div class="title">企业荣誉理由</div>
        <div class="part-content">
          <Swiper :list="awardList">
            <template v-slot:text="{ text }">
              <div class="award-text">{{ text }}</div>
            </template>
          </Swiper>
        </div>
      </div>
      <div class="part advantage">
        <div class="title">我们的优势</div>
        <div class="part-content">
          <div class="part-list">
            <div v-for="(item, index) in advantageList" :key="index" class="part-item">
              <img :src="item.imgUrl" />
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cFooter></cFooter>
    <cBotoom></cBotoom>
  </div>
</template>
<script>
import { jumpPath } from '@/utils/helper'
import cHeader from '@/components/cHeader.vue';
import cFooter from '@/components/cFooter.vue';
import cBotoom from '@/components/cBotoom.vue';
import TeacherPart from '@/components/teacherPart.vue'
import EntryPart from '@/components/entryPart.vue';
import Swiper from '@/components/swiper.vue';
import VideoPlayer from '@/components/videoPlayer.vue';
import loginIframe from '@/components/loginIframe.vue';
import vkTrack from '@/utils/_vktrack'

export default {
  data() {
    return {
      reasonList: [
        {
          imgUrl: require('./img/reason1.png'),
          textList: [
            '类母语学习环境',
            '沉浸式学习'
          ]
        },
        {
          imgUrl: require('./img/reason2.png'),
          textList: [
            '科学完善的知识体系',
            '助力学习效果'
          ]
        },
        {
          imgUrl: require('./img/reason3.png'),
          textList: [
            '人工智能与大数据应用',
            '提升学习效率'
          ]
        },
        {
          imgUrl: require('./img/reason4.png'),
          textList: [
            '随时随地在线学习',
            '灵活安排学习时间'
          ]
        },
        {
          imgUrl: require('./img/reason5.png'),
          textList: [
            '国际化专业性内容生态',
            '全面培养表达力'
          ]
        },
        {
          imgUrl: require('./img/reason6.png'),
          textList: [
            '多元化学习内容',
            '提高综合素养'
          ]
        },
      ],
      productList: [
        {
          title: '大咖英语',
          desc: '对标CFER及其他四大国际英语测评标准，强对应MC-AI话题，设置6个级别和500+主题。结合内容知识输入+语言输出+知识拓展+应用延伸，学习效果双向叠加。通过和老师高频的互动，达成语言的应用，让学生实现所学即所用，营造家庭语言环境，把英语交流日常化。',
          pagePath: '/bigstar',
          listType: 'block',
          itemType: 'column',
          list: [
            {
              title: 'L1',
              subTitle: '培养兴趣',
              itemList: [
                { text1: '词汇', text2: '300+' },
                { text1: '句型', text2: '150+' },
                { text1: '阅读量', text2: '7000+' },
              ],
              color: 's2',
            },
            {
              title: 'L2',
              subTitle: '奠定基础',
              itemList: [
                { text1: '词汇', text2: '300+' },
                { text1: '句型', text2: '200+' },
                { text1: '阅读量', text2: '8000+' },
              ],
              color: 's2',
            },
            {
              title: 'L3',
              subTitle: '基础夯实',
              itemList: [
                { text1: '词汇', text2: '400+' },
                { text1: '句型', text2: '200+' },
                { text1: '阅读量', text2: '17000+' },
              ],
              color: 's3',
            },
            {
              title: 'L4',
              subTitle: '稳步提升',
              itemList: [
                { text1: '词汇', text2: '450+' },
                { text1: '句型', text2: '200+' },
                { text1: '阅读量', text2: '21000+' },
              ],
              color: 's3',
            },
            {
              title: 'L5',
              subTitle: '知识丰富',
              itemList: [
                { text1: '词汇', text2: '450+' },
                { text1: '句型', text2: '200+' },
                { text1: '阅读量', text2: '24000+' },
              ],
              color: 's4',
            },
            {
              title: 'L6',
              subTitle: '能力进阶',
              itemList: [
                { text1: '词汇', text2: '450+' },
                { text1: '句型', text2: '200+' },
                { text1: '阅读量', text2: '25000+' },
              ],
              color: 's4',
            },
          ]
        },
        {
          title: '1对1(海外版)',
          desc: '基于美国共同核心州立标准（CCSS），参照欧洲语言共同参考框架（CEFR）相应级别的语言水平，不断强化国际通用考试所需的语言能力，打造出全面科学的课程体系，为全球青少儿提供适合的英语学习方案。设置主修课、专项课和备考类课程。',
          pagePath: '/oversea',
          listType: 'block',
          itemType: 'list',
          list: [
            {
              title: 'L1',
              subTitle: '兴趣启蒙',
              itemList: [
                '基础词汇200+',
                '主题故事及主题儿歌等45+',
                '句型60+'
              ],
              color: 's1',
            },
            {
              title: 'L2-L4',
              subTitle: '基础夯实',
              itemList: [
                '高频词、话题词、阅读词等1000+',
                '句型200+',
                '语法15+'
              ],
              color: 's2',
            },
            {
              title: 'L5-L6',
              subTitle: '技能提升',
              itemList: [
                '高频词、话题词、阅读词及学术词汇 等1400+',
                '阅读策略40+',
                '阅读体裁10+',
                '句型、语法等200+'
              ],
              color: 's3',
            },
            {
              title: 'L7-L8',
              subTitle: '综合飞跃',
              itemList: [
                '学术词汇、话题词汇以及短语等600+',
                '口语以及写作输出任务145+',
                '阅读和听力文本90+',
                '词汇策略、语法知识100+'
              ],
              color: 's4',
            },
          ]
        },
        {
          title: '主修智学AI体',
          desc: '对标CCSS和CFER，结合中国孩子的认知特点，潜心研发的一套科学、有效、符合孩子认知规律的主修智学AI体系。在沉浸式语言环境中，通过系统学习8个Level内容，培养孩子的思维力和交际力。',
          pagePath: '/ai',
          listType: 'block',
          itemType: 'text',
          list: [
            {
              title: 'L1',
              subTitle: '听说启蒙 兴趣激发',
              itemText: '70+儿歌、24个主题故事，生活场景主题内容，画风更活泼，符合孩子认知，遵循听说限行的规律，入门更容易。',
              color: 's1',
            },
            {
              title: 'L2-L4',
              subTitle: '听说突破 阅读启蒙',
              itemText: '内容更丰富，反复听、学、练、持续积累，助力听说的突破，同时，通过体系性的阅读练习逐步走向自主阅读。',
              color: 's2',
            },
            {
              title: 'L5-L6',
              subTitle: '读写进阶 综合发展',
              itemText: '多体载文本阅读作为载体，融入各项语言技能训练，启发思路，促进听说读写的综合发展。',
              color: 's3',
            },
            {
              title: 'L7-L8',
              subTitle: '学术培养 语言应用',
              itemText: '从内容难度递进，从阅读中学习如何写作，实用性更强。提升复杂信息整合能力；学会用英文深度沟通；提升自信，培养未来领导力；提升语音语调，表达清晰流利。',
              color: 's4',
            },
          ]
        },
        {
          title: '双语文化素养',
          desc: 'VIPKID双语文化素养课是专为6-15岁青少儿打造的实拍互动AI课程，主要聚焦在非遗、自然、人文三大领域。以中外教结合的双语授课模式，搭配沉浸式多媒体互动视频，结合趣味性手工实践形式，帮助孩子建立文化自信，激发孩子艺术创想，增加对文化的理解力和发展力。',
          pagePath: '/culture',
          listType: 'card',
          list: [
            {
              title: '非遗来了',
              imgUrl: require('./img/shuangyu-1.png'),
              itemText: '带领孩子探寻八位非物质文化遗产的传承人与他们的传世技艺，中外教结合双语授课，将非遗文化、人文情感、日常英语进行有效融合。结合24节云端课程翻看学习机关书。旨在将复杂的文化知识融入儿童游戏中。通过绘本、折纸、游戏和AI相结合，提高孩子对非物质文化遗产知识的理解。',
            },
            {
              title: '海洋生命奇妙之旅',
              imgUrl: require('./img/shuangyu-2.png'),
              itemText: '由VIPKID携手北京海洋馆倾情推出，结合多媒体互动视频，手工实践的形式，带孩子们揭秘海洋奥秘。在舒缓治愈的画面中，助力孩子们完成自己的海洋艺术创想，培养孩子对自我、对环境和对生命的责任感与使命感。',
            },
            {
              title: '非遗天府之旅',
              imgUrl: require('./img/shuangyu-3.png'),
              itemText: '携手四川多位非遗传承人，围绕来自四川的四项非遗主题，包括：名山制茶、成都皮影、青城武术、藏羌织绣。还原非遗的真实场景，让孩子在拓宽知识面的同时拥有属于自己的大师代表作！',
            },
            {
              title: '清明上河图',
              imgUrl: require('./img/shuangyu-4.png'),
              itemText: '全新角度看国宝，体验动态《清明上河图》及背后的技术，拓展历史、语文知识，培养英语语感。跨大语文、历史、艺术、财商、英语知识多维成长。带孩子们用双语学习与传统文化有关的词句和作文集。',
            },
          ]
        },
        {
          title: '波比星球',
          desc: '波比星球课程共设置有12个级别，总计500余节，完整覆盖儿童语言黄金期。课程大纲对标《美国CCSS标准》和《中国英语能力等级量表》，系统化科学进阶。先听说，后读写，遵循语言学习规律，让孩子轻松掌握一门硬实力。',
          pagePath: '/bobi',
          listType: 'block',
          itemType: 'list',
          list: [
            {
              title: 'L1-L4',
              subTitle: '英文儿歌 语感启蒙',
              itemList: [
                '144首英文儿歌',
                '294个启蒙词汇',
                '384句亲子口语',
                '48本英文绘本',
                '100+美式发音要点',
              ],
              color: 's1',
            },
            {
              title: 'L5-L8',
              subTitle: '听说积累 美语听说',
              itemList: [
                '144首英文儿歌',
                '294个启蒙词汇',
                '384句亲子口语',
                '48本英文绘本',
                '100+美式发音要点',
              ],
              color: 's3',
            },
            {
              title: 'L9-L12',
              subTitle: '自然拼读 开启阅读',
              itemList: [
                '132个拼读规则',
                '576个核心词汇',
                '90+高频句型',
                '220个视觉词',
                '144本拼读绘本',
              ],
              color: 's4',
            },
          ],
        },
      ],
      classDescList: [
        {
          title: '资深的国际化教师团队',
          desc: '经验丰富的国际化教师团队，用心上好每一节课',
        },
        {
          title: '超人气老师 课堂更有活力',
          desc: '教学热情充沛，课堂气氛活跃',
        },
        {
          title: '数十种以上互动环节持续吸引',
          desc: '智能AR特效，助力好效果',
        },
        {
          title: '精美的课件设计 丰富的交互游戏',
          desc: '趣味互动课堂，越好玩越投入',
        },
      ],
      techDescList: [
        {
          title: 'AI赋能',
          imgUrl: require('./img/icon1.png'),
          descList: [
            'AI赋能构建多元混合学习',
            '平台，助力个性化学习',
          ]
        },
        {
          title: '星云系统',
          imgUrl: require('./img/icon2.png'),
          descList: [
            '高可靠，低延时网络系统，',
            '覆盖全球50个国家，',
            '在16国55城建立中心传输节点',
          ]
        },
        {
          title: '安全保护体系',
          imgUrl: require('./img/icon3.png'),
          descList: [
            '保障数据安全，运营安全，',
            '通过ISO信息安全管理体系',
            '认证的在线教育企业',
          ]
        },
      ],
      awardList: [
        {
          imgUrl: require('./img/award1.png'),
          textList: [
            '2019年度',
            '中国教育国际交流协会最佳伙伴奖'
          ]
        },
        {
          imgUrl: require('./img/award2.png'),
          textList: [
            '2018年度',
            '中华人民共和国教育部卓越合作伙伴',
          ]
        },
        {
          imgUrl: require('./img/award3.png'),
          textList: [
            '2017年度',
            '中国公益企业'
          ]
        },
        {
          imgUrl: require('./img/award4.png'),
          textList: [
            '2017年度',
            '中国年度创新成长企业100强'
          ]
        },
        {
          imgUrl: require('./img/award5.png'),
          textList: [
            '澎湃教育',
            '年度影响力教育机构'
          ]
        },
        {
          imgUrl: require('./img/award6.png'),
          textList: [
            '36氪',
            '中国新经济之王“最具影响力企业”',
          ]
        },
        {
          imgUrl: require('./img/award7.png'),
          textList: [
            '网易',
            '2020年度家长满意青少年教育品牌',
          ]
        },
        {
          imgUrl: require('./img/award8.png'),
          textList: [
            '2018年度',
            '共青团中央青少年创新产品奖'
          ]
        },
        {
          imgUrl: require('./img/award9.png'),
          textList: [
            '央视网',
            '2020年度影响力在线教育品牌',
          ]
        },
        {
          imgUrl: require('./img/award10.png'),
          textList: [
            'CB Insights',
            '国内教育科技影响力榜单'
          ]
        },
        {
          imgUrl: require('./img/award11.png'),
          textList: [
            '艾媒咨询',
            '年度最佳在线教育品牌'
          ]
        },
        {
          imgUrl: require('./img/award12.png'),
          textList: [
            '2017年度',
            '时代影响力大奖'
          ]
        },
        {
          imgUrl: require('./img/award13.png'),
          textList: [
            '2017年度',
            '中国创客'
          ]
        },
        {
          imgUrl: require('./img/award14.png'),
          textList: [
            '2017年度',
            '创新品牌'
          ]
        },
        {
          imgUrl: require('./img/award15.png'),
          textList: [
            '2017年度',
            '十大创业家'
          ]
        },
        {
          imgUrl: require('./img/award16.png'),
          textList: [
            '2017年度',
            '影响力教育集团'
          ]
        },
        {
          imgUrl: require('./img/award17.png'),
          textList: [
            '2017年度',
            '影响力事件'
          ]
        },
        {
          imgUrl: require('./img/award18.png'),
          textList: [
            '2017年度',
            '中国品牌价值少儿英语机构'
          ]
        },
      ],
      advantageList: [
        {
          imgUrl: require('./img/icon4.png'),
          desc: '付费后，每位学员都有专属课程顾问全程服务，我们向学员保障服务水平',
        },
        {
          imgUrl: require('./img/icon5.png'),
          desc: '课程和教学质量严格把关，售后无忧，详情可咨询客服',
        },
        {
          imgUrl: require('./img/icon6.png'),
          desc: '为每一位学员在线隐私安全保驾护航',
        },
      ]
    }
  },
  methods: {
    jump(product) {
      const path = product.pagePath.replace('/', '')
      vkTrack.click(`parent_h5_click_to_web_${path}`)
      setTimeout(() => {
        jumpPath(product.pagePath)
      }, 300)
    }
  },
  components: {
    cHeader,
    cFooter,
    cBotoom,
    TeacherPart,
    EntryPart,
    Swiper,
    VideoPlayer,
    loginIframe
  },
};
</script>

<style lang="less" scoped>
@import "../../base.less";

.page.page-home {
  padding-bottom: 80px;
  position: relative;
  .banner {
    width: 100%;
    height: @banner-height;
    background-image: url(./img/banner.png);
    background-size: cover;
    color: #fff;
    text-align: center;
    img.logo {
      width: 260px;
      margin-top: 17px;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 150%; /* 48px */
      letter-spacing: 1px;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }
    .text-list >div {
      margin: 4px 0;
      font-weight: 500;
      line-height: 150%; /* 21px */
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
    }
  }
  .part.aboutus {
    img.stats {
      width: 100%;
    }
  }
  .part.product {
    .product-item {
      .desc-panel {
        display: flex;
        img {
          width: 12px;
          height: 12px;
          margin-top: 2px;
        }
        .text {
          margin-left: 4px;
          line-height: 26px; /* 162.5% */
          .font-desc();
        }
      }
      .block-list {
        .block-item {
          margin-top: 29px;
          .title-panel {
            .common-flex();
            justify-content: start;
            .title {
              .font(28px, 900, #2F3338);
            }
            .sub-title {
              margin-left: 10px;
              .font(20px, 500, #2F3338);
            }
          }
          .block-panel {
            margin-top: 9px;
            padding: 8px 16px;
            border-radius: 8px;
            display: flex;
            &.s1 {
              background: #FFEF80;
            }
            &.s2 {
              background: #FFE14C;
            }
            &.s3 {
              background: #FFC34E;
            }
            &.s4 {
              background: #FF8A4C;
            }
            .column-item {
              &:not(:last-child) {
                margin-right: 42px;
              }
              .text1 {
                .font(16px, 500, #2E3237);
              }
              .text2 {
                margin-top: 4px;
                .font(18px, 700, #2E3237);
              }
            }
            .ul-list {
              margin-left: 10px;
              .ul-item {
                margin: 4px 0;
                line-height: 135%; /* 21px */
                .font-desc2();
                .dot {
                  background-color: #2F3338;
                }
              }
            }
            .text {
              line-height: 150%; /* 24px */
              .font-desc2();
            }
          }
        }
      }
      .card-list {
        margin-top: 24px;
        .card-item {
          .title {
            .font(20px, 500, #2F3338);
            text-align: center;
          }
          .card-body {
            margin: 16px 0;
            border-radius: 17px;
            background-color: #fff;
            filter: drop-shadow(0px 20px 40px rgba(248, 84, 21, 0.03));
            img {
              width: 100%;
            }
            .text {
              line-height: 26px; /* 162.5% */
              padding: 16px;
              .font-desc2();
            }
          }
        }
      }
      .btn {
        width: 127px;
        height: 40px;
        line-height: 40px;
        margin: 24px auto 8px;
        border-radius: 8px;
        background: #F85415;
        box-shadow: 0px 10px 20px 0px rgba(248, 84, 21, 0.30);
        text-align: center;
        .font(14px, 400, #fff);
      }
    }
  }
  .part.class {
    .video-panel {
      margin: 0 16px;
    }
    .desc-list {
      margin: 0 16px;
      .desc-item {
        margin-top: 24px;
        display: flex;
        img {
          width: 12px;
          height: 12px;
          margin-top: 4px;
        }
        .text-panel {
          margin-left: 5px;
          .title {
            .font-title();
          }
          .desc {
            margin-top: 4px;
            .font-desc();
          }
        }
      }
    }
  }
  .part.tech {
    .card-list  {
      .card-item {
        margin: 16px;
        padding: 24px 0 24px 16px;
        border-radius: 16px;
        background: #FFFBF9;
        border: 1px solid #FEE4D9;
        border-radius: 16px;
        .common-flex();
        .title {
          .font-title();
        }
        .desc-list {
          margin-top: 4px;
          .desc-item {
            letter-spacing: 1px;
            .font-desc();
          }
        }
        img {
          width: 94px;
          height: 94px;
        }
      }
    }
  }
  .part.step {
    .desc-panel {
      padding: 0 21px;
      img {
        width: 100%;
      }
    }
  }
  .part.partner {
    .info {
      padding: 0 16px;
      .common-flex();
      img {
        width: 153px;
      }
      .line {
        width: 1px;
        height: 32px;
        background: rgba(0, 0, 0, 0.10);
        transform: rotate(30deg);
      }
    }
  }
  .part.award {
    .award-text {
      .font(18px, 400, #686868);
    }
  }
  .part.advantage {
    .part-list {
      margin: 0 30px;
      text-align: center;
      .part-item {
        margin-top: 24px;
        img {
          width: 40px;
          height: 40px;
        }
        .desc {
          line-height: 32px;
          margin-top: 8px;
          .font(18px, 350, #686868);
        }
      }
    }
  }
}
</style>
